<template>
  <div class="list listeomove">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="contractMenu">
      <el-button @click="exportlist" class="rightMenu" type="text">导出</el-button>
    </div>
    <div class="tabStart" :class="{showclass:tabStartmg, showclass1:tabStartmg1}">
      <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="1"></table-tab>
    </div>
    <div class="listMenue">
      <list-menu v-if="status === '1'" @buttonClick="buttonClick" :menuData="menudata"></list-menu>
      <div class="textBtnbox" v-if="status === '3'">
        <el-button class="textBtn" @click="cancelDemand" type="text">撤销取消</el-button>
      </div>
    </div>
    <div class="maxversion">
      <div class="maxverbox">数据版本: {{maxDataVersion}}</div>
      <global-table row-key='id' @SelectionChange='SelectionChange' @SelectAction='SelectAction' ref="SelecChangetData" :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
      ></global-table>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="1200px" :dialogShow="dialogShow" :selectTabData="selectTabData" @handleClose='handleClose' title="取消送货需求" componentName="ApplyBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' }, {label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action"></dialog-box>

    <dialog-box ref="queryDialog1" dialogWidth="1100px" :dialogShow="dialogOrderShow" :selectTabData="selectTabData" @handleClose='handleOrderClose' title="撤销取消" componentName="RevokeBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'orderAction', type:'primary', size:'mini'}]" @orderAction="orderBtn" ></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableField1, tableField2, tableData, checkEd } from './js/setting.js'
import { request, exportForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { MIME } from '@/assets/js/mime.js'
import qs from 'qs'
export default {
  components: Component.components,
  name: 'SupplierDemand',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 390) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    // pageSize = 2
    return {
      status: '1',
      dialogOrderShow: false,
      tabStartmg: false,
      dialogShow: false,
      tableNode: undefined,
      tabStartmg1: false,
      tableField: [],
      checkEd: checkEd,
      clearData: {},
      tableData: tableData,
      choiceData: [],
      revokeData: [],
      selectTabData: [],
      maxDataVersion: '',
      providerType: '',
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData },
      navData: [
        {
          label: '待响应',
          name: '1'
        },
        {
          label: '已全部响应',
          name: '2'
        },
        {
          label: '已拒绝',
          name: '3'
        }
      ]
    }
  },
  mounted () {
    // 获取送货基地列表
    var newOption = []
    request('/api/livraison/base/getBaseInfoByName?' + 'baseName=' + '', 'get').then((res) => {
      res.data.forEach(item => {
        newOption.push({
          label: item.baseName,
          value: item.baseCode
        })
      })
      this.childData.searchList[3].option = newOption
    })
  },
  created () {
    this.checkEd.dataLable = '待响应'
    if (this.providerType === 2) {
      this.tableField = [tableField1, ...tableField2]
    } else {
      this.tableField = [tableField1, ...tableField]
    }
    this.myBidData()
  },
  methods: {
    // 状态切换查询
    onSwitch: function (data) {
      this.checkEd.dataLable = data.props.label
      if (data.props.label === '待响应') {
        this.status = '1'
        this.tabStartmg = false
        if (this.providerType === 2) {
          this.tableField = [tableField1, ...tableField2]
        } else {
          this.tableField = [tableField1, ...tableField]
        }
        this.myBidData()
      }
      if (data.props.label === '已全部响应') {
        this.status = '2'
        this.tabStartmg = true
        this.tabStartmg1 = false
        if (this.providerType === 2) {
          this.tableField = [...tableField2]
        } else {
          this.tableField = [...tableField]
        }
        this.myBidData()
      }
      if (data.props.label === '已拒绝') {
        this.status = '3'
        this.tabStartmg = true
        this.tabStartmg1 = true
        if (this.providerType === 2) {
          this.tableField = [tableField1, ...tableField2]
        } else {
          this.tableField = [tableField1, ...tableField]
        }
        this.myBidData()
      }
      this.selectTabData = []
      this.checkEd.data = []
      if (this.clearData.data) {
        this.clearData.clearSelection()
      }
    },
    // 点击-创建送货单按钮或者取消送货需求按钮
    buttonClick: function (data) {
      if (data.openView === 'openView') {
        this.addDemeand(data)
      } else {
        this.adjustment()
      }
    },
    // 创建送货单
    addDemeand (data) {
      if (this.selectTabData.length === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择需要创建的送货单'
        })
      } else {
        this.$router.push({ params: { viewName: data.alias } })
        this.$emit('OpenView', { openView: data.alias })
        this.$store.commit('setPageValue', this.selectTabData)
        this.checkEd.data = []
        this.clearData.clearSelection()
      }
    },
    // 取消送货需求
    adjustment () {
      if (this.selectTabData.length === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择需取消的送货单'
        })
      } else {
        this.dialogShow = true
        this.checkEd.data = []
        // this.clearData.clearSelection()
      }
    },
    // 点击取消送货需求弹框里的确定按钮
    action () {
      var newChoiceData = []
      this.choiceData = this.$refs.queryDialog.$refs.content.tableData
      const applyExplain = this.$refs.queryDialog.$refs.content.desc
      const cancelReasonClassify = this.$refs.queryDialog.$refs.content.cancelReasonClassify
      let url = '/api/livraison/livrasion/cancelDemand'
      let returnmsg = '取消成功！'
      this.choiceData.forEach(w => {
        newChoiceData.push({
          demandCode: w.demandCode,
          demandRow: w.demandRow
        })
      })
      const obj = {
        demandCodes: JSON.stringify(newChoiceData),
        applyExplain: applyExplain
      }
      /**
       * 修改日期：2023-08-09 高天滨
       * 修改内容：对所有类型的送货需求，统一取消原因类型为如下取消原因枚举项（（不可抗力、直发、质量问题、PO延期、尾数无法凑包），当填写确认后调用接口同步SAP（当前逻辑是需求类型为JH：按计划到货的展示如下几类取消原因：
       * 删除内容：this.choiceData[0].demandType === 8
       */
      // if (this.choiceData[0].demandType === 8) {
      if (!cancelReasonClassify) {
        this.$message({
          showClose: true,
          message: '请选择取消原因',
          type: 'warning'
        })
        return false
      } else {
        obj.cancelReasonClassify = cancelReasonClassify
      }
      if (this.choiceData[0].demandType === 8 || this.choiceData[0].demandType === 11) {
        url = '/api/livraison/livrasion/updateDemand'
        returnmsg = '提交成功，待领导审批！'
      }
      // }
      if (applyExplain) {
        request(url, 'POST', obj).then((res) => {
          if (res.code === '200') {
            if (res.code === '200') {
              this.$message({
                showClose: true,
                message: returnmsg,
                type: 'success'
              })
            }
            this.clearData.clearSelection()
            this.dialogShow = false
            this.myBidData()
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请填写说明',
          type: 'warning'
        })
      }
    },

    // 撤销
    cancelDemand () {
      if (this.selectTabData.length === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择需要撤销的送货单'
        })
      } else {
        this.dialogOrderShow = true
      }
    },
    // 点击撤销弹框里的确定按钮
    orderBtn () {
      var newRevokeData = []
      this.revokeData = this.$refs.queryDialog1.$refs.content.tableData
      this.revokeData.forEach(w => {
        newRevokeData.push({
          demandCode: w.demandCode,
          demandRow: w.demandRow
        })
      })
      const obj = [
        ...newRevokeData
      ]
      request('/api/livraison/livrasion/undoCancelDemand', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.clearData.clearSelection()
          this.dialogOrderShow = false
          this.myBidData()
        }
      })
    },

    // 获取供应商交付-送货需求列表数据
    myBidData () {
      let rkksTime = ''
      let rkjsTime = ''
      let rkksTime1 = ''
      let rkjsTime1 = ''
      if (this.childData.searchData.rkTime) {
        rkksTime = dayjs(this.childData.searchData.rkTime[0]).format('YYYY-MM-DD')
        rkjsTime = dayjs(this.childData.searchData.rkTime[1]).format('YYYY-MM-DD')
      }
      if (this.childData.searchData.demandTimeST) {
        rkksTime1 = dayjs(this.childData.searchData.demandTimeST[0]).format('YYYY-MM-DD')
        rkjsTime1 = dayjs(this.childData.searchData.demandTimeST[1]).format('YYYY-MM-DD')
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        status: this.status,
        ...this.childData.searchData,
        releaseBeginTime: rkksTime,
        releaseEndTime: rkjsTime,
        demandTimeBeginTime: rkksTime1,
        demandTimeEndTime: rkjsTime1
      }
      this.searchData = obj
      request('/api/livraison/livrasion/getLivrasionDemandDataByUserId', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.providerType = res.data.providerType
          if (this.providerType === 2 && this.checkEd.dataLable === '待响应') {
            this.tableField = [tableField1, ...tableField2]
          }
          this.maxDataVersion = res.data.maxDataVersion
          this.tableData = res.data.iPage.records
          if (this.paginationData.total !== res.data.iPage.total) {
            this.paginationData.total = res.data.iPage.total
          }
        }
      })
    },
    // 获取选中的数据
    SelectionChange (data) {
      this.selectTabData = data
    },
    // 获取当前待响应选中的数据
    SelectAction (selection, row, newthis) {
      this.checkEd.data = selection
      this.clearData = newthis.$refs.table
      if (this.checkEd.dataLable === '待响应') {
        if (selection.length <= 0 ? true : row.baseName === selection[0].baseName) {
          this.selectTabData = selection
        }
      }
    },

    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'MyBidRating' }, query: { objData: data.id } })
      } else {
      }
    },
    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    handleOrderClose () {
      this.dialogOrderShow = false
    },
    // 导出
    exportlist () {
      if (this.tableData.length < 1) {
        this.$message.error('先查询出结果再导出')
        return
      }
      var file = { name: '送货需求明细.xls' }
      exportForm('/api/livraison/livrasion/demandExport?' + qs.stringify(this.searchData), 'post').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  }
}
</script>

<style scoped lang="scss">
.maxversion{
  position: relative;
  .maxverbox{
    font-size: 12px;
    color: #666;
    position: absolute;
    top: -36px;
    right: 30px;
  }
}
.listMenue{
  height: 58px;
}
.listeomove{
  :deep(.el-table__header .el-checkbox__inner) {
    display: none !important;
  }
}
.tableList{
  .has-gutter{
    :deep(.cell){
      display: none !important;
    }
  }
}
.sqtz-btn{
  position: relative;
}
.btn-pos{
  position: absolute;
  left: 150px;
  top: 8px;
  font-size: 16px;
}
.tabStart{
  padding: 0 30px;
  :deep(.el-tabs__header){
    margin: 0;
  }
}
// .showclass{
//   margin-bottom: 20px;
// }
.showclass1{
  margin-bottom: 0;
}
.textBtnbox{
  padding: 8px 30px;
}
.textBtn{
  font-size: 16px;
}
.contractMenu {
  position: relative;
  z-index: 1;
  .el-button {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 16px;
  }
}</style>
